.main-sidenav {
   width: var(--sidenav-width);
   background-color: var(--black-900);
   position: fixed;
   height: 100%;
   overflow: auto;
   box-sizing: border-box;
   padding: var(--spacing-l);
   font-family: var(--title-family);
}

.item_menu a {
   color: #fff;
   transition: all 0.2s;
}

.item_menu a:hover {
   color: #e70000;
}