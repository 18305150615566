.header {
   /*background: var(--black-900)*/
   font-size: var(--size-title-m);
}

.login_signup {
   display: flex;
   flex-direction: row-reverse;
   gap: var(--spacing-m);
}

.login_button {
   background: var(--black-900);
   border-radius: var(--raduis-full);
   padding: 0 var(--spacing-m);
   color: #fff;
}

.signup_button {
   border: 1px solid var(--black-100);
   border-radius: var(--raduis-full);
   padding: 0 var(--spacing-m);
   color: var(--black-900);
}