code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
   
  /* Dimensioni di base del font */
  --base-font-size: 16px; 
  
  /* Scale Factor per H1-H6 ed altri titoli */
  --scale-factor: 1.200; 

  /* Dimensione di base Altezza della linea (Definice il ritmo verticale) */
  --line-height: 1.625; 

  /* Altezza della linea in pixel */
  --base-line-height: calc(var(--base-font-size) * var(--line-height)); 
  
  /* Linea di base verticale */
  --margin-vertical-rhythm: 1.5rem;  

  /* Pre-calcola le potenze di Scale Factor per i titoli */
  --size-h6: calc(var(--base-font-size) * var(--scale-factor)); /* H6 */
  --size-h5: calc(var(--size-h6) * var(--scale-factor)); /* H5 */
  --size-h4: calc(var(--size-h5) * var(--scale-factor)); /* H4 */
  --size-h3: calc(var(--size-h4) * var(--scale-factor)); /* H3 */
  --size-h2: calc(var(--size-h3) * var(--scale-factor)); /* H2 */
  --size-h1: calc(var(--size-h2) * var(--scale-factor)); /* H1 */

  --size-title-m: calc((var(--base-font-size) + var(--size-title-s)) / 2);
  --size-title-s: calc(var(--base-font-size) / var(--scale-factor)); /* Small */
  --size-title-xs: calc(var(--size-title-s) / var(--scale-factor)); /* Over Sub Line */

  --size-title-l: calc(var(--size-h1) * var(--scale-factor)); /* Titolo L (maggiore di H1) */
  --size-title-xl: calc(var(--size-title-l) * var(--scale-factor)); /* Titolo XL */
  --size-title-xxl: calc(var(--size-title-xl) * var(--scale-factor)); /* Titolo XXL */

  /* Font Family UI/UX & Title */
  --title-family: 'Source Sans 3', -apple-system, BlinkMacSystemFont, "Segoe UI",  Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --body-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI",  Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  /* Spacing */
  --spacing-s: 0.5rem;
  --spacing-m: 1rem;
  --spacing-l: 1.5rem;

  /* General height */
  --height-s: 2rem;
  --height-m: 2.5rem;
  --height-l: 3rem;

  /* Sidenav Width */
  --sidenav-width: 256px; /* 256px = 16REM (ovvero 16 volte la dimensione del font */

  /* Carbon Black */
  --black-50: #F1F1F1;
  --black-100:#D8D8D8;
  --black-200:#C0C0C0;
  --black-300:#A7A7A7;
  --black-400:#8E8E8E;
  --black-500:#767676;
  --black-600:#5D5D5D;
  --black-700:#444444;
  --black-800:#2C2C2C;
  --black-900:#131313;

  /* Ghost White */
  --white-50: #ffffff;
  --white-100:#F5F5F5;
  --white-200:#EAEAEA;
  --white-300:#E0E0E0;
  --white-400:#D5D5D5;
  --white-500:#CBCBCB;
  --white-600:#C0C0C0;
  --white-700:#B6B6B6;
  --white-800:#ABABAB;
  --white-900:#A1A1A1;

  /* Electrict Blue */
  --blue-50: #F0F6FD;
  --blue-100:#C0DBF7;
  --blue-200:#90BFF1;
  --blue-300:#60A4EB;
  --blue-400:#3088E4;
  --blue-500:#006DDC;
  --blue-600:#0062C6;
  --blue-700:#0057B0;
  --blue-800:#004C9A;
  --blue-900:#004184;

  /* Electric Indigo */
  --indigo-50: #F5F5FD;
  --indigo-100:#D7D7F7;
  --indigo-200:#B9B9F1;
  --indigo-300:#9B9BEA;
  --indigo-400:#7D7DE3;
  --indigo-500:#6060dc;
  --indigo-600:#5656C6;
  --indigo-700:#4D4DB0;
  --indigo-800:#43439A;
  --indigo-900:#3A3A84;

  /* Excel Green */
  --green-50: #F0F7F2;
  --green-100:#C0DEC7;
  --green-200:#90C49D;
  --green-300:#60AA74;
  --green-400:#30904A;
  --green-500:#007620;
  --green-600:#006A1D;
  --green-700:#005E1A;
  --green-800:#005316;
  --green-900:#004713;

  /* Blood Red */
  --red-50: #FDF0F1;
  --red-100:#F8C0C7;
  --red-200:#F2909D;
  --red-300:#EC6073;
  --red-400:#E53049;
  --red-500:#DE0020;
  --red-600:#C8001D;
  --red-700:#B2001A;
  --red-800:#9B0016;
  --red-900:#850013;

  /* Box Shadow */
  --shadow-1:rgba(0, 0, 0, 0.15) 0px 0px 15px -5px;
  --shadow-2:rgba(0,0,0, 0.15) 0px 5px 10px -3px;
  --shadow-3:rgba(0,0,0, 0.15) 0px 10px 10px -10px;
  --shadow-4:rgba(0,0,0, 0.15) 0px 5px 15px -5px;

  /* Border Radius */
  --radius-xs: 3px;
  --radius-s: 6px;
  --radius-m: 12px;
  --radius-l: 18px;
  --raduis-full: 100px;
}

/**
* Typography - Website e UI System
* CSS Per la tipografia del sito
* Update: 09/07/24
*/

body, html {
  margin: 0;
  padding: 0;
  font-size: var(--base-font-size)!important; /* 16px */
  font-weight: 400;
  line-height: var(--base-line-height); /* 16px */
  box-sizing: border-box;
  font-family: var(--body-family);
  color: rgb(0, 0, 0);
}

button {
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  font-size: var(--size-title-m);
  font-weight: 500;
  justify-content: center;
  min-height: var(--height-s);
  pointer-events: auto;
  border-color: transparent;
  border-radius: var(--raduis-full);
  border-width: 1px;
  padding: 0.5rem 0.875rem;
  cursor: pointer;
}

/* Stile dei titoli principali del sito web */
h1, h2, h3, h4, h5, h6, .title-l, .title-xl, .title-xxl { 
  font-family: var(--title-family);
  font-weight: 700; 
} 

/* Ritmo verticale per i titoli */
h1, h2, h3, h4, h5, h6, p, .title-l, .title-xl, .title-xxl, small, .oversub-line {
  margin: var(--spacing-l) 0 var(--spacing-l) 0;
  line-height: var(--line-height);
  color: var(--black-800);
}

/* Dimensioni font H1-H6 */
h1 { font-size: var(--size-h1); }
h2 { font-size: var(--size-h2); }
h3 { font-size: var(--size-h3); }
h4 { font-size: var(--size-h4); }
h5 { font-size: var(--size-h5); }
h6 { font-size: var(--size-h6); }

/* Dimensioni font titoli Display (molto grandi) */
.title-xxl { font-size: var(--size-title-xxl);  }
.title-xl { font-size: var(--size-title-xl);  }
.title-l { font-size: var(--size-title-l);  }

/* Dimensioni font per i tag html secondari */
small { 
  font-size: var(--size-title-xs); 
  font-weight: 600;
}

.oversub-line { 
  font-size: var(--size-title-s); 
  font-weight: 500;
}

/* Dimensioni font per i Pulsanti */
.btn, .btn_s, .btn_m, .btn_l {
  font-size: var(--size-title-s);
  font-weight: 600;
}

/* Dimensioni font per i Link */
a { 
  color: var(--blue-600); 
  text-decoration: none;
}
a:hover { 
  color: var(--blue-800); 
}
a:visited { 
  color: var(--indigo-700); 
}
a:visited:hover { 
  color: var(--indigo-900); 
}

/**
* Main Style Motustrength
* Nella sezione sottostante raggruppiamo tutti gli stili principali del sito.
* Elementi che riguardano l'header, il footer, box, tabelle etc..
* Update: 09/07/24
*/

/* Wrapper globale - In questo modo l'altezza di ogni pagina del sito sarà sempre piena indipendentemente dal contenuto */
.page-wrapper {
  display: flex;
  min-height: 100vh;
} 

/* Wrapper Principale - In modo differenta da page-wrapper, questo contiene elementi come header, footer, sidebar etc.. */
.main-wrapper {
  margin-left: var(--sidenav-width);
  padding: var(--spacing-l); 
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* Wrapper Strutturale - Con questo wrapper definiamo la larghezza delle pagine, il margin e padding per dare una spaziatura uniforme agli elementi principali del sito */
.layout-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-l);
  box-sizing: border-box;
  height: 100%;
}

/* Header Styles -  L'abbreviazione sh sta per "Site Header" indicando che quel dato elemento appartiene all'header */
.site-header {
  top: 0;
  position: sticky;
  background: var(--white-50);
  min-height: 80px;
  box-sizing: border-box;
  z-index: 1000;
}

.sh-container {
  display: flex;
  height: 100%;
  gap: 10px;
  align-items: center;
}

.sh-left-section {
  display: flex;
  width: 100%;
}

.sh-right-section {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

/* Content Styles */
.site-content {
  flex-grow: 1;
}

/* Footer Styles */
.site-footer {
  background-color: #f4f4f4;
  border-top: 1px solid #ddd;
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.google-login-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}



/* Loader Style */
.spinner-container {
  display: flex;
  justify-content: center;   /* Centra orizzontalmente */
  align-items: center;       /* Centra verticalmente */
  height: 100px;             /* Altezza del contenitore */
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;    /* Colore di base */
  border-top: 4px solid #3498db; /* Colore della parte in movimento */
  border-radius: 50%;           /* Cerchio */
  animation: spin 1s linear infinite; /* Rotazione continua */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
